import axios, { AxiosResponse } from "axios";
import { useAtom } from "jotai";
import { environment } from "../../environment";
import { useLogin } from "../login";
import { errorCatcher, useSnackbar } from "../../hooks";
import {
    IGetGeneratedMockupRequest,
    IGeneratedMockup,
    IGeneratedMockupAtom,
    generatedMockupsAtom,
} from "./types";

type GetGeneratedMockups = (body: IGetGeneratedMockupRequest) => Promise<IGeneratedMockup[]>;
type GetGeneratedMockupsForProduct = (product_id: number) => IGeneratedMockup[] | undefined;


type GeneratedMockupKeyFunctions = {
    get: GetGeneratedMockups,
    getGeneratedForProduct: GetGeneratedMockupsForProduct
}

type UseGeneratedMockups = [
    IGeneratedMockupAtom,
    GeneratedMockupKeyFunctions
];

export const useGeneratedMockups = (): UseGeneratedMockups => {
    const [generatedMockups, setGeneratedMockups] = useAtom(generatedMockupsAtom);
    const [,authToken, loginFunctions] = useLogin();
    const [,setSnackbar] = useSnackbar();

    const get = async (body: IGetGeneratedMockupRequest): Promise<IGeneratedMockup[]> => {
        const getRepsonse: AxiosResponse<IGeneratedMockup[]> | void = await axios.post(`${environment.mockupsUrl}/generated`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getRepsonse) {
            if (generatedMockups[body.product_id]) {
                const generated = getRepsonse.data;
                const allGenerated = generatedMockups[body.product_id];

                const needToAdd = [];

                for (let i = 0; i < generated.length; i++) {
                    let foundMatch = false;
                    for (let x = 0; x < allGenerated.length; x++) {
                        if (allGenerated[x].mockup_id === generated[i].mockup_id) foundMatch = true;
                    }
                    if (!foundMatch) needToAdd.push(generated[i]);
                }

                setGeneratedMockups({
                    ...generatedMockups,
                    [body.product_id]: [...allGenerated, ...needToAdd]
                })
            } else {
                setGeneratedMockups({
                    ...generatedMockups,
                    [body.product_id]: getRepsonse.data
                })
            }
            return getRepsonse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting generated headers for product. Please try again later.'
            })
        }
        return [];
    }

    const getGeneratedForProduct = (product_id: number): IGeneratedMockup[] | undefined => {
        return generatedMockups[product_id];
    }

    const keyFunctions: GeneratedMockupKeyFunctions = {
        get,
        getGeneratedForProduct
    }

    return [
        generatedMockups,
        keyFunctions
    ]
}