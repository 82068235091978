export interface PaginatedResponseType<T> {
    results: T[];
    count: number;
    hasNext: boolean;
    hasBefore: boolean;
    channel: ChannelType;
}

export enum CrudOperations {
    CREATE = 'Add',
    READ = 'View',
    UPDATE = 'Edit',
    DELETE = 'Delete',
    SOFT_DELETE = 'Archive',
}

export enum ChannelType {
    SHOPIFY = 'SHOPIFY',
    WALLMATES = 'WALLMATES'
}
