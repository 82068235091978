

export const env = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return {
            production: false,
            languagesUrl: "http://localhost:3100/translation/languages",
            translationUrl: "http://localhost:3100/translation/translations",
            translationKeysUrl: "http://localhost:3100/translation/translation-keys",
            mockupsUrl: "http://localhost:3100/mockups",
            generatePreviewUrl: "http://localhost:8000/mockups/generate-preview",
            insertMockupUrl: "http://localhost:8080/upload-mockup",
            productsUrl: "http://localhost:3100/designs",
            brandsUrl: "http://localhost:3100/brands",
            artTypeUrl: "http://localhost:3100/art-type",
            mockupCategoriesUrl: 'http://localhost:3100/mockup-category',
            ordersUrl: 'http://localhost:3100/orders',
            collectionsUrl: 'http://localhost:3100/collections',
            designHeadersUrl: 'http://localhost:3100/design-headers',
            fontsUrl: 'http://localhost:3100/fonts',
            queuesUrl: 'http://localhost:3100/queue',
            loginUrl: 'http://localhost:3100/employee',
            lineItemUrl: 'http://localhost:3100/line-items',
            scanUrl: 'http://localhost:3100/scanner',
            printerUrl: 'http://localhost:3100/printers',
            printNodeUrl: 'https://api.printnode.com/printjobs',
            jdmlUrl: 'http://localhost:3100/jdml',
            materialsUrl: 'http://localhost:3100/materials',
            authenticationUrl: 'http://localhost:3100/authentication',
            pricingUrl: 'http://localhost:3100/pricing',
            billingUrl: 'http://localhost:3100/billing',
            socketUrl: 'ws://localhost:3100',
            checklistUrl:  "http://localhost:3100/checklist",
            customersUrl: 'http://localhost:3100/customers',
            shippingUrl: 'http://localhost:3100/shipping',
            invoicesUrl: 'http://localhost:3100/invoices',
            giftwrapsUrl: 'http://localhost:3100/giftwraps',
            bulkUploadUrl: 'http://localhost:3100/bulk-upload'
        }
    } else if (process.env.REACT_APP_ENVIRONMENT === 'test') {
        return {
            production: false,
            languagesUrl: "https://test.app.wallblush.com/translation/languages",
            translationUrl: "https://test.app.wallblush.com/translation/translations",
            translationKeysUrl: "https://test.app.wallblush.com/translation/translation-keys",
            mockupsUrl: "https://test.app.wallblush.com/mockups",
            generatePreviewUrl: "https://test.mockup-engine.wallmates.com/mockups/generate-preview",
            insertMockupUrl: "https://test.mockup-engine.wallmates.com/upload-mockup",
            productsUrl: "https://test.app.wallblush.com/designs",
            brandsUrl: "https://test.app.wallblush.com/brands",
            artTypeUrl: "https://test.app.wallblush.com/art-type",
            mockupCategoriesUrl: 'https://test.app.wallblush.com/mockup-category',
            ordersUrl: 'https://test.app.wallblush.com/orders',
            collectionsUrl: 'https://test.app.wallblush.com/collections',
            designHeadersUrl: 'https://test.app.wallblush.com/design-headers',
            fontsUrl: "https://test.app.wallblush.com/fonts",            
            queuesUrl: 'https://test.app.wallblush.com/queue',
            loginUrl: 'https://test.app.wallblush.com/employee',
            lineItemUrl: 'https://test.app.wallblush.com/line-items',
            scanUrl: 'https://test.app.wallblush.com/scanner',
            printerUrl: 'https://test.app.wallblush.com/printers',
            printNodeUrl: 'https://api.printnode.com/printjobs',
            jdmlUrl: 'https://test.app.wallblush.com/jdml',
            materialsUrl: 'https://test.app.wallblush.com/materials',
            authenticationUrl: 'https://test.app.wallblush.com/authentication',
            pricingUrl: 'https://test.app.wallblush.com/pricing',
            billingUrl: 'https://test.app.wallblush.com/billing',
            socketUrl: 'wss://test.app.wallblush.com',
            checklistUrl:  "https://test.app.wallblush.com/checklist",
            customersUrl: 'https://test.app.wallblush.com/customers',
            shippingUrl: 'https://test.app.wallblush.com/shipping',
            invoicesUrl: 'https://test.app.wallblush.com/invoices',
            giftwrapsUrl: 'https://test.app.wallblush.com/giftwraps',
            bulkUploadUrl: 'https://test.app.wallblush.com/bulk-upload'
        }
    } else { // must be production environment
        return {
            production: true,
            languagesUrl: "https://app.wallblush.com/translation/languages",
            translationUrl: "https://app.wallblush.com/translation/translations",
            translationKeysUrl: "https://app.wallblush.com/translation/translation-keys",
            mockupsUrl: "https://app.wallblush.com/mockups",
            generatePreviewUrl: "https://mockup-engine.wallmates.com/mockups/generate-preview",
            insertMockupUrl: "https://mockup-engine.wallmates.com/upload-mockup",
            productsUrl: "https://app.wallblush.com/designs",
            brandsUrl: "https://app.wallblush.com/brands",
            artTypeUrl: "https://app.wallblush.com/art-type",
            mockupCategoriesUrl: 'https://app.wallblush.com/mockup-category',
            ordersUrl: 'https://app.wallblush.com/orders',
            collectionsUrl: 'https://app.wallblush.com/collections',
            designHeadersUrl: 'https://app.wallblush.com/design-headers',
            fontsUrl: "https://app.wallblush.com/fonts",
            queuesUrl: 'https://app.wallblush.com/queue',
            loginUrl: 'https://app.wallblush.com/employee',
            lineItemUrl: 'https://app.wallblush.com/line-items',
            scanUrl: 'https://app.wallblush.com/scanner',
            printerUrl: 'https://app.wallblush.com/printers',
            printNodeUrl: 'https://api.printnode.com/printjobs',
            jdmlUrl: 'https://app.wallblush.com/jdml',
            materialsUrl: 'https://app.wallblush.com/materials',
            authenticationUrl: 'https://app.wallblush.com/authentication',
            pricingUrl: 'https://app.wallblush.com/pricing',
            billingUrl: 'https://app.wallblush.com/billing',
            socketUrl: 'wss://app.wallblush.com',
            checklistUrl:  "https://app.wallblush.com/checklist",
            customersUrl: 'https://app.wallblush.com/customers',
            shippingUrl: 'https://app.wallblush.com/shipping',
            invoicesUrl: 'https://app.wallblush.com/invoices',
            giftwrapsUrl: 'https://app.wallblush.com/giftwraps',
            bulkUploadUrl: 'https://app.wallblush.com/bulk-upload'
        }
    }
}

export const environment = env();