import { atom } from 'jotai';
import { ChannelType, PaginatedResponseType } from '../../globals/types';
import { IBrandPricing } from '../pricing';

export interface IInvoice {
    id?: number;
    invoice_name: string;
    created_at: string;
    brand_id: number;
    total_cost: number;
    order_id: number;
    charge_status: 'SUCCEEDED' | 'REFUNDED' | 'PENDING' | 'FAILED';
    invoice_line_items: IInvoiceLineItem[];
}

export interface IInvoiceLineItem {
    id: number;
    created_at: string;
    quantity: number;
    cost: number;
    unit_cost: number;
    pricing: IBrandPricing;
}

export interface GetInvoicesBody {
    brand_id: number;
    page_size: number;
    offset_pages: number;
    querystring: string | null;
}

export const invoicesAtom = atom<PaginatedResponseType<IInvoice>>({ results: [], count: 0, hasNext: false, hasBefore: false, channel: ChannelType.WALLMATES });
export const invoicesLoadingAtom = atom<boolean>(false); 
export const singleInvoiceAtom = atom<IInvoice | null>(null);
