import React, { ChangeEventHandler } from 'react';
import { Input } from "@headlessui/react";
import { clsx } from "clsx";

export interface SliderInputProps {
    value: number;
    onChange: ChangeEventHandler<HTMLInputElement>;
    min?: number;
    max?: number;
    step?: number;
    type?: 'range' | 'number' | 'both';
    disabled?: boolean;
    rangeProps?: any;
    inputProps?: any;
}

export const RangeSlider = ({
    value,
    onChange,
    min,
    max,
    step = 1,
    type = 'both',
    disabled = false,
    rangeProps = {
    },
    inputProps = {
    },
}: SliderInputProps) => {
    return (
        <>
            {type === 'both' || type === 'range' ? (
                <Input
                    type="range"
                    className={clsx([
                        rangeProps?.className,
                        'w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
                    ])}
                    min={min || 0}
                    max={max || 100}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    step={step}
                    {...rangeProps}
                />
            ) : null }

            {type === 'number' || type === 'both' ? (
                <Input
                    type="number"
                    className={clsx(`
                        ${inputProps?.className} 
                        mt-1 w-20 border border-gray-300 px-2 py-1 formkit-input bg-gray-50 text-gray-900 rounded-lg 
                        focus:ring-blue-500 visible:border-black focus:border-blue-500 block dark:bg-gray-700 
                        dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`
                    )}
                    min={min || 0}
                    max={max || 100}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    step={step}
                />
            ) : null}
        </>
    );
}