import React from 'react';

export interface Route {
    path: RouteConstants,
    element: React.ReactElement
}

export enum RouteConstants {
    HOME = '/',
    LOGIN = '/login',
    PRODUCT_HOME = '/product',
    PRODUCT_CREATE = '/product/create',
    PRODUCT_BULK_UPLOAD = '/product/bulk-upload',
    PRODUCT_EDIT = '/product/:productId',
    PRODUCT_EDIT_SAMPLE = '/product/:productId/sample-selection',
    PRODUCT_EDIT_MOCKUP = '/product/:productId/mockup-selection',
    SETTINGS_HOME = '/settings',
    TRANSLATIONS = '/translations',
    TRANSLATION_KEYS = '/translations/key',
    MANAGE_CHECKLIST = '/manage-checklist',
    MOCKUP = '/mockup',
    BRANDS = '/brands',
    BRAND = '/brands/:brand_id',
    JDML = '/production/jdml',
    JDML_SECTIONS = '/production/jdml/:jdmlId',
    DROPPER = '/production/dropper',
    SCANNER = '/production/scanner',
    ORDERS = '/orders',
    ORDER = '/orders/:order_id',
    LINE_ITEM = '/orders/:order_id/line-item/:line_item_id',
    COLLECTION = '/collection',
    FAILED_ORDER = '/orders/failed/:order_queue_id',
    USERS = '/users',
    CUSTOMERS = '/customers',
    CUSTOMER = '/customers/:customer_id',
    WEIGH_STATION = '/production/weigh-station',
    SHIP_STATION = '/production/ship-station',
    INVOICES = '/invoices',
    INVOICE = '/invoices/:invoice_id',
    ANY = '*'
}

const Home = React.lazy(() => import('./pages/home/Home.component'));
const ProductHome = React.lazy(() => import('./pages/products/home/ProductHome.component'));
const ProductCreate = React.lazy(() => import('./pages/products/create/ProductCreate.component'));
const ProductBulkUpload = React.lazy(() => import('./pages/products/bulk-upload/ProductBulkUpload.component'));
const SettingsHome = React.lazy(() => import ('./pages/settings/home/SettingsHome.component'));
const Language = React.lazy(() => import('./pages/language/language/Language.component'));
const LanguageKey = React.lazy(() => import('./pages/language/translation-key/TranslationKey.component'));
const ManageChecklist = React.lazy(() => import('./pages/checklist/ManageChecklist.component'));
const Mockup = React.lazy(() => import('./pages/mockups/Mockup.component'));
const Product = React.lazy(() => import('./pages/products/product/Product.component'));
const SampleSelection = React.lazy(() => import('./pages/products/product/SampleSelection.component'));
const MockupSelection = React.lazy(() => import('./pages/products/product/MockupSelection.component'));
const BrandsHome = React.lazy(() => import('./pages/brands/brands.component'));
const BrandHome = React.lazy(() => import('./pages/brands/brand.component'));
const OrdersHome = React.lazy(() => import('./pages/orders/Orders/Orders.component'));
const OrderHome = React.lazy(() => import('./pages/orders/Order.component'));
const Collection = React.lazy(() => import('./pages/collections/Collection.component'));
const JDML = React.lazy(() => import('./pages/jdmls/JDMLHome.component'));
const JDMLSections = React.lazy(() => import('./pages/jdmls/jdml/JDML.component'));
const LineItem = React.lazy(() => import('./pages/line-items/line-items.component'));
const FailedOrder = React.lazy(() => import('./pages/orders/failed-order/failed-order.component'));
const Scanner = React.lazy(() => import('./pages/scanner/scanner.component'));
const Dropper = React.lazy(() => import('./pages/jdmls/dropper/dropper.component'));
const Users = React.lazy(() => import('./pages/users/users.component'));
const Customers = React.lazy(() => import('./pages/customers/Customers.component'));
const Customer = React.lazy(() => import('./pages/customers/Customer.component'));
const Weight = React.lazy(() => import('./pages/weigh/WeighStation.component'));
const Shipping = React.lazy(() => import('./pages/shipping/Shipping.component'));
const Invoices = React.lazy(() => import('./pages/invoice/Invoices.component'));
const Invoice = React.lazy(() => import('./pages/invoice/Invoice.component'));

export const dashboardRoutes: Route[] = [
    { path: RouteConstants.HOME, element: <Home /> },
    { path: RouteConstants.PRODUCT_HOME, element: <ProductHome /> },
    { path: RouteConstants.PRODUCT_CREATE, element: <ProductCreate /> },
    { path: RouteConstants.PRODUCT_BULK_UPLOAD, element: <ProductBulkUpload /> },
    { path: RouteConstants.PRODUCT_EDIT, element: <Product /> },
    { path: RouteConstants.PRODUCT_EDIT_SAMPLE, element: <SampleSelection /> },
    { path: RouteConstants.PRODUCT_EDIT_MOCKUP, element: <MockupSelection /> },
    { path: RouteConstants.SETTINGS_HOME, element: <SettingsHome /> },
    { path: RouteConstants.TRANSLATIONS, element: <Language /> },
    { path: RouteConstants.TRANSLATION_KEYS, element: <LanguageKey /> },
    { path: RouteConstants.MANAGE_CHECKLIST, element: <ManageChecklist /> },
    { path: RouteConstants.BRANDS, element: <BrandsHome /> },
    { path: RouteConstants.BRAND, element: <BrandHome /> },
    { path: RouteConstants.MOCKUP, element: <Mockup /> },
    { path: RouteConstants.ORDERS, element: <OrdersHome /> },
    { path: RouteConstants.ORDER, element: <OrderHome /> },
    { path: RouteConstants.SETTINGS_HOME, element: <SettingsHome /> },
    { path: RouteConstants.TRANSLATIONS, element: <Language /> },
    { path: RouteConstants.TRANSLATION_KEYS, element: <LanguageKey /> },
    { path: RouteConstants.MOCKUP, element: <Mockup /> },
    { path: RouteConstants.COLLECTION, element: <Collection /> },
    { path: RouteConstants.LINE_ITEM, element: <LineItem /> },
    { path: RouteConstants.FAILED_ORDER, element: <FailedOrder /> },
    { path: RouteConstants.SCANNER, element: <Scanner /> },
    { path: RouteConstants.ANY, element: <Home /> },
    { path: RouteConstants.JDML, element: <JDML />},
    { path: RouteConstants.JDML_SECTIONS, element: <JDMLSections /> },
    { path: RouteConstants.DROPPER, element: <Dropper /> },
    { path: RouteConstants.USERS, element: <Users /> },
    { path: RouteConstants.CUSTOMERS, element: <Customers /> },
    { path: RouteConstants.CUSTOMER, element: <Customer /> },
    { path: RouteConstants.WEIGH_STATION, element: <Weight /> },
    { path: RouteConstants.SHIP_STATION, element: <Shipping /> },
    { path: RouteConstants.INVOICES, element: <Invoices /> },
    { path: RouteConstants.INVOICE, element: <Invoice /> }
];