import Axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import {
    IFetchGeneratePreviewRequestBody,
    IGeneratedPreview,
    IGeneratePreviewAttributes,
    previewAtom,
    previewLoadingAtom,
} from "./types";

type FetchGeneratedPreviewType = (body: IFetchGeneratePreviewRequestBody) => Promise<IGeneratedPreview | void>;
type GenerateMockupType = (body: IFetchGeneratePreviewRequestBody) => Promise<IGeneratedPreview | void>;

type GeneratedPreviewsKeyFunctions = {
    fetchPreview: FetchGeneratedPreviewType;
    generateMockup: GenerateMockupType;
    resetPreviews: () => void;
}

type UsePreviews = [
    { [key: number] : IGeneratedPreview },
    GeneratedPreviewsKeyFunctions
];

const defaultAttributes: IGeneratePreviewAttributes = {
    pattern_scale: 100,
    preview_width: 600,
    shadow_opacity: 100,
    enhance_shadows: false,
}

export const useGeneratedPreviews = (): UsePreviews => {
    const [previews, setPreviews] = useAtom(previewAtom);
    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();
    const setPreviewLoading = useSetAtom(previewLoadingAtom);

    const fetchGeneratedPreview = async (body: IFetchGeneratePreviewRequestBody): Promise<IGeneratedPreview | void> => {
        body.attributes = {
            ...defaultAttributes,
            ...body.attributes
        }
        setPreviewLoading(true);
        return await Axios.post(`${environment.mockupsUrl}/generate-preview`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        })
            .then((result: AxiosResponse<IGeneratedPreview>) => {
                if (result.data && body.mockup_id === result.data.mockup_id) {
                    setPreviews((prev) => {
                        return {
                            ...prev,
                            [body.mockup_id]: result.data
                        }
                    });
                    return result.data;
                } else {
                    setSnackbar({
                        show: true,
                        snackbarLevel: 'error',
                        text: 'There was an error while fetching the generated previews. Please try again later.'
                    })
                    setPreviews((prev) => {
                        return {
                            ...prev,
                            [body.mockup_id]:  result?.data || 'Unknown error'
                        }
                    });
                }
            })
            .catch((err: any) => errorCatcher(err, loginFunctions.logout))
            .finally(() => setPreviewLoading(false));
    }

    const generateMockup = async (body: IFetchGeneratePreviewRequestBody): Promise<IGeneratedPreview | void> => {
        body.attributes = {
            ...defaultAttributes,
            ...body.attributes
        }
        setPreviewLoading(true);
        return await Axios.post(`${environment.mockupsUrl}/generate-mockup`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        })
            .then((result: AxiosResponse<IGeneratedPreview>) => {
                if (result.data && body.mockup_id === result.data.mockup_id) {
                    setSnackbar({
                        show: true,
                        snackbarLevel: 'info',
                        text: 'Mockup generated successfully! Mockups can be found under the products > "Generated Mockups" section.'
                    })
                    return result.data;
                } else {
                    setSnackbar({
                        show: true,
                        snackbarLevel: 'error',
                        text: 'There was an error while fetching the generated previews. Please try again later.'
                    })
                    setPreviews((prev) => {
                        return {
                            ...prev,
                            [body.mockup_id]:  result?.data || 'Unknown error'
                        }
                    });
                }
            })
            .catch((err: any) => errorCatcher(err, loginFunctions.logout))
            .finally(() => setPreviewLoading(false));
    }

    const keyFunctions: GeneratedPreviewsKeyFunctions = {
        fetchPreview: fetchGeneratedPreview,
        generateMockup: generateMockup,
        resetPreviews: () => setPreviews([]),
    }

    return [
        previews,
        keyFunctions
    ]
}

