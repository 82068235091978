import { atom } from "jotai";
import { CustomersFilter } from "../../pages/customers/Customers.component";
import { IOrderDTO } from "../order";
import { ChannelType, PaginatedResponseType } from "../../globals/types";

export const customersAtom = atom<PaginatedResponseType<ICustomer>>({results: [], count: 0, hasNext: false, hasBefore: false, channel: ChannelType.WALLMATES});
export const customerAtom = atom<ICustomer>({} as ICustomer);
export const customerTypesAtom = atom<ICustomerType[]>([]);
export const customerAddressTypesAtom = atom<ICustomerAddressType[]>([]);
export const customerLoadingAtom = atom<boolean>(false);
export const customersLoadingAtom = atom<boolean>(false);

export interface ICustomerAddress {
    id?: number,
    address_type: number,
    street_address: string,
    city: string,
    state: string,
    zip: string,
    country: string,
  }
  
  export interface ICustomerMetadata {
    email?: string,
    email_address?: string,
    phone_number?: string,
    phone?: string,
    type: number,
  }
  
  export interface ICustomer {
    id: number
    customer_id: number | string,
    customer_type: number,
    first_name: string,
    last_name: string,
    email_address: string,
    phone_number: string,
    shipping_first_name: string,
    shipping_last_name: string,
    orders_count: number,
    addresses: ICustomerAddress[] | [],
    orders: IOrderDTO[] | [],
    customer_metadata: ICustomerMetadata[] | [],
  }
  
  export interface IUpdateCustomerRequest {
    id: number;
    first_name: string;
    last_name: string;
    shipping_first_name: string;
    shipping_last_name: string;
    email_address: string;
    phone_number: string;
    email: boolean;
    sms: boolean;
    customer_type: number;
  }
  
  export interface IUpdateCustomerAddressRequest {
    id?: number;
    address_type: number;
    street_address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  }
  
  export interface ICreateCustomerRequest {
    first_name: string,
    last_name: string,
    email_address: string,
    phone_number: string,
    shipping_first_name: string,
    shipping_last_name: string,
    type: number,
  }
  
  export interface IGetCustomersQuery {
    page_count: number;
    page: number;
    filter_by: CustomersFilter;
  }
  
  export interface ICustomerType {
    id: number,
    name: string,
  }
  
  export interface ICustomerAddressType {
    id: number,
    name: string,
  }