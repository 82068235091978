import { useAtom, useSetAtom } from "jotai";
import { errorCatcher, errorMessageCatcher, snackbarSettingsAtom } from "../../hooks/useSnackbar";
import { useLogin } from "../login/login";
import axios, { AxiosResponse } from "axios";
import { environment } from "../../environment";
import { PaymentMethod, Source } from "@stripe/stripe-js";
import { IBrandBilling, BrandBillingUpdateBody, InsertPaymentMethodRequestBody, paymentMethodsAtom, brandBillingAtom, brandBillingLoadingAtom } from "./types";


type CurrentBrandBilling = IBrandBilling | null;
type GetBrandBilling = (brand_id: number) => Promise<IBrandBilling | null>;
type UpdateBrandBilling = (body: BrandBillingUpdateBody) => Promise<IBrandBilling | null>;
type GetPaymentMethods = (customer_id: string) => Promise<PaymentMethod[] | null>;
type InsertPaymentMethod = (body: InsertPaymentMethodRequestBody) => Promise<Source | null>;
type VerifyBankAccount = (stripe_customer_id: string, customer_source_id: string, amount_1: number, amount_2: number) => Promise<Source | null>;
type SetDefaultPaymentMethod = (stripe_customer_id: string, customer_source_id: string) => Promise<boolean | null>;
type DeletePaymentMethod = (stripe_customer_id: string, customer_source_id: string) => Promise<boolean | null>;


export type BrandBillingKeyFunctions = {
    get: GetBrandBilling,
    update: UpdateBrandBilling,
    getPaymentMethods: GetPaymentMethods,
    insertPaymentMethod: InsertPaymentMethod,
    verifyBankAccount: VerifyBankAccount,
    setDefaultPaymentMethod: SetDefaultPaymentMethod,
    deletePaymentMethod: DeletePaymentMethod
}

type UseBrandBilling = [
    CurrentBrandBilling,
    PaymentMethod[],
    BrandBillingKeyFunctions
]

export const useBrandBilling = (): UseBrandBilling => {

    const [paymentMethods, setPaymentMethods] = useAtom(paymentMethodsAtom);
    const [brandBilling, setBrandBilling] = useAtom(brandBillingAtom);
    const [,setBrandBillingLoading] = useAtom(brandBillingLoadingAtom);

    const setSnackbar = useSetAtom(snackbarSettingsAtom);
    const [,authToken, loginFunctions] = useLogin();

    const getBrandBilling = async (brand_id: number): Promise<IBrandBilling | null> => {
        setBrandBillingLoading(true);
        const results: AxiosResponse<IBrandBilling> | void = await axios.get(`${environment.billingUrl}?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (results) {
            setBrandBillingLoading(false);
            setBrandBilling(results.data);
            return results.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting brand billing. Please try again later.'
            });
            setBrandBillingLoading(false);
            return null;
        }
    }

    const updateBrandBilling = async (body: BrandBillingUpdateBody): Promise<IBrandBilling | null> => {
        setBrandBillingLoading(true);
        const results: AxiosResponse<IBrandBilling> | void = await axios.post(`${environment.billingUrl}/details`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (results) {
            setBrandBillingLoading(false);
            setBrandBilling(results.data);
            return results.data;
        } else {
            setBrandBillingLoading(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting brand billing. Please try again later.'
            });
            return null;
        }
    }

    const getPaymentMethods = async (customer_id: string): Promise<PaymentMethod[] | null> => {
        const results: AxiosResponse<PaymentMethod[]> | void = await axios.get(`${environment.billingUrl}/payment-methods?customer_id=${customer_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (results) {
            setPaymentMethods(results.data);
            return results.data;
        } else {
            setPaymentMethods([]);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting payment methods. Please try again later.'
            });
            return null;
        }
    }

    const insertPaymentMethod = async (body: InsertPaymentMethodRequestBody): Promise<Source | null> => {
        const result: AxiosResponse<Source> | string = await axios.post(`${environment.billingUrl}/payment-methods`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorMessageCatcher(err, loginFunctions.logout));

        if (typeof result !== 'string') {
            await getPaymentMethods(body.stripe_customer_id);
            return result.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: result
            });
            return null;
        }
    }

    const deletePaymentMethod = async (stripe_customer_id: string, customer_source_id: string): Promise<boolean | null> => {
        const result: AxiosResponse<any> | void = await axios.post(`${environment.billingUrl}/delete/payment-method`, {
            stripe_customer_id, customer_source_id
        }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (result) {
            await getPaymentMethods(stripe_customer_id);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Success'
            });
            return true;
        }

        setSnackbar({
            show: true,
            snackbarLevel: 'error',
            text: 'Failed to delete payment method. Please try again later'
        });
        return null;
    }

    const setDefaultPaymentMethod = async (stripe_customer_id: string, customer_source_id: string): Promise<boolean | null> => {
        const result: AxiosResponse<any> | void = await axios.post(`${environment.billingUrl}/default/payment-method`, {
            stripe_customer_id, customer_source_id
        }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (result) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Success'
            });
            return true;
        } 

        setSnackbar({
            show: true,
            snackbarLevel: 'error',
            text: 'Failed to set payment method as default. Please try again later'
        });
        return null;
    }

    const verifyBankAccount = async (stripe_customer_id: string, customer_source_id: string, amount_1: number, amount_2: number): Promise<Source | null> => {
        const result: AxiosResponse<Source> | string = await axios.post(`${environment.billingUrl}/verify/payment-method`, {
            stripe_customer_id, customer_source_id, deposit_1: amount_1, deposit_2: amount_2
        }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorMessageCatcher(err, loginFunctions.logout));

        if (typeof result !== 'string') {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Success'
            });
            return result.data;
        }

        setSnackbar({
            show: true,
            snackbarLevel: 'error',
            duration: 100000,
            text: result || 'Failed to verify bank account. Please try again later'
        });
        return null;
    }

    const keyFunctions: BrandBillingKeyFunctions = {
        get: getBrandBilling,
        update: updateBrandBilling,
        getPaymentMethods,
        insertPaymentMethod,
        deletePaymentMethod,
        setDefaultPaymentMethod,
        verifyBankAccount
    }

    return [
        brandBilling,
        paymentMethods,
        keyFunctions
    ]
}