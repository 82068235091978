import Axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { 
    IGetOrderLineItemsQuery, 
    IUpdateOrderLineItemQuery, 
    IUploadGiftwrapQuery, 
    orderLineItemsAtom, 
    orderLineItemsLoadingAtom 
} from "./types";
import { IOrderLineItemDTO } from "../scanner";

type GetOrderLineItems = (params: IGetOrderLineItemsQuery) => Promise<IOrderLineItemDTO[]>;
type GetOrderLineItemById = (id: number) => IOrderLineItemDTO | undefined;
type UpdateOrderLineItem = (body: IUpdateOrderLineItemQuery) => Promise<IUpdateOrderLineItemQuery>;
type UploadGiftwrapImage = (body: IUploadGiftwrapQuery) => Promise<void>;

type OrderLineItemKeyFunctions = {
    get: GetOrderLineItems,
    getById: GetOrderLineItemById,
    update: UpdateOrderLineItem,
    uploadGiftwrap: UploadGiftwrapImage
}

type UseOrderLineItems = [
    IOrderLineItemDTO[],
    OrderLineItemKeyFunctions
]

export const useOrderLineItems = (): UseOrderLineItems => {
    const [orderLineItems, setOrderLineItems] = useAtom(orderLineItemsAtom);
    const setOrderLineItemsLoading = useSetAtom(orderLineItemsLoadingAtom);
    const [,authToken, loginFunctions] = useLogin();
    const [,setSnackbar] = useSnackbar();

    const getOrderLineItems: GetOrderLineItems = async (params: IGetOrderLineItemsQuery): Promise<IOrderLineItemDTO[]> => {
        setOrderLineItemsLoading(true);
        const results: AxiosResponse<IOrderLineItemDTO[]> | void = await Axios.get(`${environment.lineItemUrl}?brand_id=${params.brand_id}&order_id=${params.order_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        })
        .catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (results) {
            setOrderLineItems(results.data);
            setOrderLineItemsLoading(false);
            return results.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error getting order line items for order. Please try again later'
            });
        }
        setOrderLineItemsLoading(false);
        setOrderLineItems([]);
        return [];
    }

    const getOrderLineItemById = (id: number): IOrderLineItemDTO | undefined => {
        return orderLineItems.find((lineItem: IOrderLineItemDTO) => lineItem.id === id);
    }

    const updateOrderLineItem = async (body: IUpdateOrderLineItemQuery): Promise<IUpdateOrderLineItemQuery> => {
        setOrderLineItemsLoading(true);
        const result: AxiosResponse<IUpdateOrderLineItemQuery> = await Axios.put(environment.lineItemUrl, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });

        if (result) {
            setOrderLineItemsLoading(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Print Range has been successfully updated!',
            });
            return result.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error updating the order line item. Please try again later'
            });
        }
        setOrderLineItemsLoading(false);
        return result;
    }

    const uploadGiftwrapImage = async(body: IUploadGiftwrapQuery): Promise<void> => {
        const result = await Axios.post(environment.giftwrapsUrl, body, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Wallmates-Auth': authToken
            }
        });
        if (result) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Giftwrap Image has been successfully uploaded!',
            });
            return result.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error uploading giftwrap image. Please try again later'
            });
        }
        return result;
    }

    const orderLineItemKeyFunctions: OrderLineItemKeyFunctions = {
        get: getOrderLineItems,
        getById: getOrderLineItemById,
        update: updateOrderLineItem,
        uploadGiftwrap: uploadGiftwrapImage
    }

    return [
        orderLineItems,
        orderLineItemKeyFunctions
    ]

}