import { atom } from "jotai";

export const pricingTemplatesAtom = atom<IPricingTemplate[]>([]);
export const populatedPricingTemplatesAtom = atom<IPopulatedPricingTemplate[]>([]);
export const pricingTemplatesLoadingAtom = atom<boolean>(false);
export const internalPricingAtom = atom<IBrandPricing[] | null>(null);
export const pricingTemplateSourcesAtom = atom<IPricingSource[] | null>(null);

export interface IPricingTemplate {
    id?: number;
    created_at: string;
    brand_id: number;
    brand_pricing_id: number;
    name: string;
}

export interface IPopulatedPricingTemplate {
    id?: number;
    created_at: string;
    brand_id: number;
    name: string;
    pricings: IBrandPricing[];
}

export interface IBrandPricing {
    id?: number;
    created_at?: string;
    brand_id: number;
    price: number;
    pricing_template_id: number;
    pricing_source: IPricingSource;
}

export interface IPricingSource {
    id: number;
    created_at: string;
    source: string;
    default_price: number;
    charge_trigger: string;
    is_pricing_template_field: boolean;
    source_display_name: string;
}